import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location} >
        <Helmet title={siteTitle} />
        <Hero />
        <div className="spotlight-divider">
          <div className="highlight-panel">
              <p className="center">
                <img className="thumb" src="/assets/img/icons/screenbrush.png" alt="Website Design &amp; Development" />
              </p>
              <h3 className="center">Website Development</h3>
              <p>What does your website say about you? Does it capture your visitors attention? Does it let them know that you're trustworthy? <a href="/contact-us">Let us help you grow</a> your business and discover what your website was always meant to be. </p>
          </div>
          <div className="highlight-panel">
              <p className="center">
                <img className="thumb" src="/assets/img/icons/report.png" alt="Graphic Design, Marketing Services" />
              </p>
              <h3 className="center">Marketing Strategy</h3>
              <p>
                Do you know the secret to achieving outstanding marketing results? 
                <a href="/contact-us">Think outside the box with us</a> and develop an effective strategy that will blow your competition out of the water.
              </p>
          </div>
          <div className="highlight-panel">
              <p className="center">
              <img className="thumb" src="/assets/img/icons/writing.png" alt="Branding. Logo Design" />
              </p>
              <h3 className="center">Corporate Identity</h3>
              <p>A great identity is more than a simple logo. Your brand needs to be recognizable, timeless and memorable. Let our hands <a href="/contact-us">Bring your business to life</a> by creating an extraordinary image for your company.</p>
          </div>
          <div className="clear"></div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex;